import React from 'react';
import Page from './hoc/Page/Page';
import Routes from './routes';

const App = () => {
  return (
    <Page>
      <Routes />
    </Page>
  );
};

export default App;
