import * as yup from 'yup';

export const changePasswordValidationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&]).{8,}$/,
      'Password should contain atleast a capital letter, a lowercase letter, a number and a special character and should be at least 8 characters long'
    ),
  confirmPassword: yup
    .string()
    .required('Confirm password  is required')
    .when('password', {
      is: (password) => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], "Passwords don't match"),
    }),
});

export const loginValidationSchema = yup.object({
  email: yup.string().email('Email is not valid').required('Email is required'),
  password: yup.string().required('Password is required'),
});

export const addUserValidationSchema = yup.object({
  name: yup.string().required('Name is required'),
  lastname: yup.string().required('Lastname is required'),
  email: yup.string().email('Email is not valid').required('Email is required'),
  phoneNumber: yup
    .string()
    .required('Phone is required')
    .matches(/^[0-9 ()+]+$/, 'Phone number is not valid'),
  role_id: yup.string().required('Role is required'),
  trackerImei: yup
    .string()
    .nullable()
    // .required("IMEI/Tracker ID is required")
    .matches(/^[0-9]{15}(,[0-9]{15})*$/, 'Imei is not in the correct format'),
  password: yup.string().when('sendActivationEmail', {
    is: (sendActivationEmail) =>
      sendActivationEmail == 'passwordSetEmail' ? false : true,
    then: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&]).{8,}$/,
        'Password should contain atleast a capital letter, a lowercase letter, a number and a special character and should be at least 8 characters long'
      ),
  }),
  confirmPassword: yup
    .string()
    .when('sendActivationEmail', {
      is: (sendActivationEmail) =>
        sendActivationEmail == 'passwordSetEmail' ? false : true,
      then: yup.string().required('Confirm password  is required'),
    })
    .when('password', {
      is: (password) => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], "Passwords don't match"),
    }),
});

export const editUserValidationSchema = yup.object({
  name: yup.string().required('Name is required'),
  lastname: yup.string().required('Lastname is required'),
  email: yup.string().email('Email is not valid').required('Email is required'),
  phoneNumber: yup
    .string()
    .required('Phone is required')
    .matches(/^[0-9 ()+]+$/, 'Phone number is not valid'),
  role_id: yup.string().required('Role is required'),
  trackerImei: yup
    .string()
    .nullable()
    // .required("IMEI/Tracker ID is required")
    .matches(/^[0-9]{15}(,[0-9]{15})*$/, 'Imei is not in the correct format'),
  password: yup
    .string()
    .nullable()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&]).{8,}$/,
      'Password should contain atleast a capital letter, a lowercase letter, a number and a special character and should be at least 8 characters long'
    ),
});

export const addCompanyValidationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Email is not valid').required('Email is required'),
  expirationDate: yup.string().required('Expiration date is required'),
  phoneNumber: yup
    .string()
    .required('Phone is required')
    .matches(/^[0-9 ()+]+$/, 'Phone number is not valid'),
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&]).{8,}$/,
      'Password should contain atleast a capital letter, a lowercase letter, a number and a special character and should be at least 8 characters long'
    ),
  confirmPassword: yup
    .string()
    .required('Confirm password  is required')
    .when('password', {
      is: (password) => (password && password.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], "Passwords don't match"),
    }),
});

export const addVehicleValidationSchema = yup.object({
  name: yup.string().required('Name is required'),
});

export const passwordEmptyValidation = (values) => {
  if (values.password.length) {
    if (!values?.confirmPassword?.length) {
      return {
        confirmPassword: 'Confirm password is required',
      };
    }
    if (values.confirmPassword !== values.password) {
      return {
        confirmPassword: "Passwords don't match",
      };
    }
  }
};
